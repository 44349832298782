// custom typefaces
import "@fontsource/inria-sans"
import "@fontsource/inter"
import "@fontsource/inder"
import "@fontsource/merriweather"
import "@fontsource/ibm-plex-mono"

// normalize CSS across browsers
import "./src/normalize.css"

// Highlighting for code blocks
//import "prismjs/themes/prism-tomorrow.css"

// custom CSS styles
import "./src/style.css"


//import Prism from 'prism-react-renderer/prism'
//;(typeof global !== 'undefined' ? global : window).Prism = Prism // gotta do this one otherwise it'll say Prism is not defined

import { Prism } from "prism-react-renderer";

(typeof global !== "undefined" ? global : window).Prism = Prism

// First import prism-markup-templating because otherwise it breaks php which depends on it.
// https://github.com/PrismJS/prism/issues/2769
await import('prismjs/components/prism-markup-templating')
await import('prismjs/components/prism-go')
await import('prismjs/components/prism-php')
await import('prismjs/components/prism-python')
await import('prismjs/components/prism-javascript')
await import('prismjs/components/prism-jsx')
await import('prismjs/components/prism-xml-doc')
await import('prismjs/components/prism-markdown')
await import('prismjs/components/prism-diff')
await import('prismjs/components/prism-cshtml')
await import('prismjs/components/prism-css')
await import('prismjs/components/prism-json')
await import('prismjs/components/prism-vim')
